import React, { useState, useEffect } from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.webp";
import ContactFrom from "../../components/ContactFrom/ContactFrom.jsx";
import Header from "../../partials/header/Header.jsx";

const ContactFromContainer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const loadTrustpilotWidget = () => {
      const widget = document.querySelector(".trustpilot-widget");
      if (window.Trustpilot && widget) {
        window.Trustpilot.loadFromElement(widget, true);
      }
    };
    document
      .querySelectorAll("script[src*='trustpilot']")
      .forEach((el) => el.remove());

    const script = document.createElement("script");
    script.src =
      "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;
    script.onload = loadTrustpilotWidget;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>
      <Header />
      <div
        className="min-h-screen w-full relative flex items-center justify-center bg-gradient-to-r from-primary to-secondary-100 overlay-two"
        style={{
          backgroundImage: isMobile
            ? "url(/bgImg2.png)"
            : `url(${process.env.PUBLIC_URL}/images/about/aboutus.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* <Link
        to={"/"}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        
        <img src={logo} alt="logo" className="w-32" />
      </Link> */}
        {/* <br /> */}
        <div className="max-w-[92%] md:max-w-[650px] text-white px-4 sm:px-6 md:px-12 mt-15 py-6 md:py-16 rounded-md relative z-10 flex flex-col justify-center items-center mx-auto text-center">
          <h2
            className="text-2xl sm:text-3xl md:text-5xl leading-tight font-bold text-white"
            style={{ fontFamily: "Utopia, serif" }}
          >
            Contact Us
          </h2>
          <p className="mt-4 text-sm sm:text-base">
            With our SEO services in Dubai, you'll reach the top of Google's
            rankings & hold the throne for a long, long time.
          </p>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="offset-lg-2 col-lg-8">
            <SectionTitle
              headingOption="fz-32"
              title="Let’s talk about your project"
              subTitle="We have made it easy for clients to reach us and get their solutions weaved"
            />
            <ContactFrom />
          </div>
        </div>
      </div>

      <div
        className="trustpilot-widget mt-4"
        data-locale="en-US"
        data-template-id="56278e9abfbbba0bdcd568bc"
        data-businessunit-id="67bee5cb11f3ffaeab097e09"
        data-style-height="80px"
        data-style-width="100%"
        data-theme="light"
      >
        {/* <a
          href="https://www.trustpilot.com/review/nsmarketing.ae"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a> */}
      </div>
    </div>
  );
};

export default ContactFromContainer;
